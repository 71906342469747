import React from "react";

const Blog = () => {
  return (
    <div className="p-4">
      <h1 className="text-4xl font-bold mb-4">Blog</h1>
      <p className="text-lg">Welcome to our blog! Stay tuned for updates.</p>
    </div>
  );
};

export default Blog;
